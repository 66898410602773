/**
  When editing this file, make sure to sync it with the styles in the frontent!
*/
.content {
  @apply text-body-1;
  letter-spacing: normal !important;
}

.contentBody1 {
  @apply text-body-1;
}

.contentBody2 {
  @apply text-body-2;
}
.contentAppBody1 {
  @apply text-body-2;
}

.content :global(.tldr) {
  @apply p-m border !border-primary-800 rounded-2xs mt-m mb-2m;
}

.content :global(*) {
  clear: both;
}

.content :global(.align-center) {
  @apply text-center;
}

.content :global(.tldr > p) {
  @apply mb-2xs;
}

.content :global(.tldr > :last-child) {
  @apply mb-none;
}

.content :global(img) {
  @apply max-w-full rounded-2xs my-m inline-block;
}

.content :global(img.img_rounded) {
  @apply rounded-2xs;
}

.content :global(img.img_rounded) {
  @apply rounded-2xs;
}

.content :global(img.img_border) {
  @apply border border-neutral-150;
}

.content :global(img.img_shadow) {
  @apply shadow-3;
}

.content :global(figure) {
  @apply my-m mx-auto flex w-fit flex-col;
}

.content :global(blockquote) {
  @apply -ml-3s pl-2s border-l-m border-l-primary-800;
}

.content :global(figure img) {
  @apply m-none;
}

.content :global(figcaption) {
  @apply text-neutral-425 text-body-2 text-left mt-2xs;
}

.content :global(a) {
  @apply text-primary-800;
}

.content :global(ul) {
  @apply list-disc pl-3s marker:text-primary-800 mt-s;
}

.content :global(ol) {
  @apply list-decimal pl-3s marker:text-primary-800 mt-s;
}

.content :global(ul:not(:last-child)),
.content :global(ol:not(:last-child)) {
  @apply mb-s;
}

.content :global(.toc li) {
  @apply text-primary-800;
}

.content :global(h1) {
  @apply text-h1;
}

.content :global(h2) {
  @apply text-h2 scroll-m-3xl;
}

.content :global(* + h2) {
  @apply mt-3m;
}

.content :global(h3) {
  @apply text-h3;
}

.content :global(* + h3) {
  @apply mt-2m;
}

.content :global(h4) {
  @apply text-h4;
}

.content :global(p) {
  @apply my-s;
}

.content :global(.toc) {
  @apply my-m;
}

.content :global(.toc h2) {
  @apply mb-2xs text-h2;
}

.content :global(ul li + li),
.content :global(ol li + li) {
  @apply mt-2xs;
}

.content :global(p:first-child) {
  @apply mt-none;
}

.content :global(p:last-child) {
  @apply mb-none;
}

.content :global(p + h2) {
  @apply mt-2m;
}

.content :global(h2 + p) {
  @apply mt-s;
}

.content :global(h1 + p) {
  @apply mt-3s;
}

.content :global(hr) {
  @apply h-xs bg-center bg-no-repeat border-none my-3m;
  background-image: url("data:image/svg+xml,%3Csvg fill='none' height='3' viewBox='0 0 50 3' width='50' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23a1afcb'%3E%3Crect height='3' rx='1.5' width='3' x='.5'/%3E%3Crect height='3' rx='1.5' width='3' x='23.5'/%3E%3Crect height='3' rx='1.5' width='3' x='46.5'/%3E%3C/g%3E%3C/svg%3E");
}

.content :global(hr::before) {
  @apply absolute -left-m w-xs h-xs rounded-full bg-neutral-50 mx-auto border-none;
  content: "";
}

.content :global(table) {
  @apply border-separate border-spacing-none border border-neutral-50 rounded-2xs;
}

.content :global(caption) {
  @apply text-neutral-425 text-body-2 text-center mb-2xs;
}

.content :global(td) {
  @apply p-s border-neutral-50 border-spacing-none;
}

.content :global(thead tr:last-child td) {
  @apply border-b border-neutral-50;
}

.content :global(thead) {
  @apply bg-neutral-12 text-neutral-675/50;
}

.content :global(tr + tr td) {
  @apply border-t;
}

.content :global(td + td) {
  @apply border-l-[1px];
}

.content :global(tr:last-child td) {
  @apply border-b-none;
}

.content :global(tr:last-child td:first-child) {
  @apply border-r-none;
}

.content :global(tr:first-child td:first-child) {
  @apply border-l-none;
}

.content :global(em) {
  font-family: PSFournier;
  font-size: 108%;
  font-style: normal;
}

.content :global(.list-numbered) {
  @apply list-none p-none;
  counter-reset: my-counter;
}

.content :global(.list-numbered li) {
  counter-increment: my-counter;
  @apply relative pl-2m;
}

.content :global(.list-numbered li:before) {
  @apply rounded-xs bg-primary-25 text-primary-800 left-none border border-primary-800 flex items-center justify-center font-semibold;
  content: counter(my-counter) ".";
  position: absolute;
  line-height: 1;
  width: 32px;
  height: 28px;
  top: 2px;
}

.content :global(.list-numbered-headline) {
  @apply list-none p-none;
  counter-reset: my-counter;
}

.content :global(.list-numbered-headline li > :first-child) {
  @apply relative pl-2m;
  counter-increment: my-counter;
}

.content :global(.list-numbered-headline li > :first-child:before) {
  @apply rounded-xs bg-primary-25 text-primary-800 left-none border border-primary-800 inline-flex items-center justify-center font-semibold text-body-1 mr-2xs;
  position: absolute;
  content: counter(my-counter) ".";
  line-height: 1;
  width: 32px;
  height: 28px;
  top: 3px;
}

.content :global(.button) {
  @apply whitespace-nowrap transition inline-flex items-center justify-center no-underline text-neutral-0 bg-primary-800 hover:bg-primary-825 active:bg-primary-850 disabled:opacity-50 disabled:cursor-not-allowed focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-primary-800 px-3s py-[14px] text-button-2 font-book rounded-s w-full m:w-fit;
}

.content :global(.table-wrapper) {
  @apply w-full max-w-full overflow-x-auto;
}

.content :global(.columns) {
  @apply flex flex-col m:grid grid-cols-12 gap-m my-3s;
}

.content :global(.columns img) {
  @apply m-none;
}

.content :global(.columns.cols-5-7 > div:last-child),
.content :global(.columns.cols-7-5 > div:first-child) {
  @apply col-span-7;
}

.content :global(.columns.cols-5-7 > div:first-child),
.content :global(.columns.cols-7-5 > div:last-child) {
  @apply col-span-5;
}
