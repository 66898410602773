.button {
  width: 20px;
  height: 20px;
  position: relative;
}

.bar {
  display: block;
  position: absolute;
  height: 2px;
  width: 16px;
  background: currentColor;
  border-radius: 1px;
  opacity: 1;
  left: 2px;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.bar:nth-child(1) {
  top: 3px;
  transform-origin: left center;
}

.bar:nth-child(2) {
  top: 9px;
  transform-origin: left center;
}

.bar:nth-child(3) {
  top: 15px;
  transform-origin: left center;
}

.button.open .bar:nth-child(1) {
  transform: rotate(45deg);
  width: 21px;
  top: 2px;
  left: 2px;
}

.button.open .bar:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.button.open .bar:nth-child(3) {
  transform: rotate(-45deg);
  width: 21px;
  top: 17px;
  left: 2px;
}
